<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.business-process.bp-instances')"
    :canCreate="$store.state.bpInstCreatePermission"
    :createToolTip="
      $t('global.header.newFem', [
        $t('global.business-process.bp-instance').toLowerCase(),
      ])
    "
    :can-delete="$store.state.bpInstDeletePermission && selectedRows.length > 0"
    :can-clear-filters="filterActive"
    :delete-message="deleteMsg"
    :tree-enabled="treeEnabled"
    has-tree-toggle
    @newItem="create"
    @deleteItem="onDelete"
    @toggleTree="onTreeToggle"
    @clearFilters="onClear"
  >
    <div class="filter-container" ref="filters" v-mutate="onMutate">
      <v-row dense>
        <v-col cols="2" v-for="([key, options], i) in filterOptions" :key="i">
          <auto-complete-filter
            :items="options"
            v-model="keyFilter['key' + (i + 1)]"
            @change="onTopFilter"
            :disabled="treeEnabled"
            :label="key"
          ></auto-complete-filter
        ></v-col>

        <v-col cols="2">
          <auto-complete-filter
            :items="fileEnvNames"
            :label="$t('global.environment.environment')"
            v-model="topSelectedFileEnv"
            :disabled="treeEnabled"
            @change="onTopFilter"
          ></auto-complete-filter>
        </v-col>

        <v-col cols="2" align-self="center"
          ><v-checkbox
            class="mt-0 pt-0"
            v-model="myBps"
            hide-details
            :label="$t('global.concepts.my-bps')"
            @change="onTopFilter"
          ></v-checkbox
        ></v-col>

        <v-spacer></v-spacer>

        <v-col cols="1"
          ><v-text-field
            :label="$t('global.action.go-to-instance')"
            outlined
            v-model="idFilter"
            :rules="[noTagsRule]"
            persistent-placeholder
            @keyup.enter="goToInstance"
          ></v-text-field
        ></v-col>
        <v-col cols="1">
          <v-btn color="primary" @click="goToInstance" :disabled="!idFilter">{{
            $t("global.action.go")
          }}</v-btn>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="2">
          <auto-complete-filter
            :items="definitionNames"
            :label="$t('global.business-process.definition')"
            v-model="selectedDefinition"
            @change="onTopFilter"
          ></auto-complete-filter>
        </v-col>

        <v-col cols="2"
          ><v-text-field
            :label="$t('global.concepts.title')"
            outlined
            v-model="titleFilter"
            :rules="[noTagsRule]"
            persistent-placeholder
            @keyup.enter="onTopFilter"
          ></v-text-field
        ></v-col>
      </v-row>
    </div>

    <v-row dense style="margin-top: 24px">
      <v-col cols="2" v-if="treeEnabled">
        <auto-complete-filter
          :items="fileEnvNames"
          :label="$t('global.environment.environment')"
          v-model="treeSelectedFileEnv"
          @change="onEnvSelect"
          style="
            position: sticky;
            top: calc(var(--headerHeight) + var(--filtersHeight));
            z-index: 1;
            background-color: #f2f7fb;
            padding-top: 6px;
          "
        ></auto-complete-filter>
        <v-treeview
          :items="[folderTree]"
          :active.sync="treeActive"
          :open="treeOpen"
          active-class="ooliba-basic-table-tree-item-active"
          activatable
          dense
          hoverable
          transition
        >
          <template v-slot:prepend="{ open }">
            <v-icon x-small>
              {{ open ? "pic-folder-opened-fill" : "pic-folder-fill" }}
            </v-icon>
          </template>
          <template #label="{ item, active }">
            <v-chip small v-if="active" class="tree-chip">{{
              item?.name
            }}</v-chip>
            <v-chip small class="transparent-tree-chip" v-else>
              {{ item?.name }}</v-chip
            ></template
          >
        </v-treeview>
      </v-col>

      <v-col :cols="treeEnabled ? 10 : 12">
        <ooliba-basic-table
          v-if="loaded"
          v-model="selectedRows"
          :headers="headers"
          :items="items"
          :items-per-page="parseInt($store.state.itemsPerPage)"
          :show-select="$store.state.bpInstDeletePermission"
          sort-by="id"
          sort-desc
          must-sort
          :loading="busy"
          :server-items-length="numItems"
          :height="tableHeight(82, items?.length, true)"
          :reset-page="resetPage"
          @row-clicked="onRowClick"
          @options-changed="onOptionsChanged"
          style="
            position: sticky;
            top: calc(var(--headerHeight) + var(--filtersHeight));
            padding-top: 6px;
          "
        >
          <template #[`item.fileEnvLabel`]="{ item }">
            <file-env-chip
              :color="item.fileEnvColor"
              :label="item.fileEnvLabel"
            >
            </file-env-chip>
          </template>
          <template #[`item.local`]="{ item }">
            <check-icon :value="item.local" />
          </template>
          <template #[`item.dateCreated`]="{ item }">
            {{ formatDate(item.dateCreated) }}</template
          >
        </ooliba-basic-table>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import CheckIcon from "@/components/CheckIcon";
import FileEnvChip from "@/components/FileEnvChip";
import AutoCompleteFilter from "@/components/AutoCompleteFilter";
import { noTags } from "@/model/rules";
import { formatDate, tableHeight } from "@/model/util";

export default {
  name: "BPInstanceList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
    CheckIcon,
    FileEnvChip,
    AutoCompleteFilter,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.id"), value: "id" },
        { text: this.$t("global.concepts.title"), value: "title" },
        {
          text: this.$t("global.business-process.definition"),
          value: "definitionName",
          sortable: false,
        },
        {
          text: this.$t("global.environment.environment"),
          value: "fileEnvLabel",
          sortable: false,
        },
        {
          text: this.$t("global.business-process.local"),
          value: "local",
          align: "center",
          sortable: false,
        },
        { text: this.$t("global.concepts.created-on"), value: "dateCreated" },
        {
          text: this.$t("global.concepts.status"),
          value: "status",
          sortable: false,
        },
      ],
      items: [],
      bps: [],

      filterOptions: [],
      keyFilter: {},
      treeEnabled: false,
      fileEnvNames: [],
      treeSelectedFileEnv: undefined,
      folderTree: {},
      treeActive: [],
      treeKeyFilter: {},
      topSelectedFileEnv: undefined,
      definitionNames: [],
      selectedDefinition: undefined,
      titleFilter: undefined,
      idFilter: undefined,
      myBps: undefined,
      treeOpen: [],

      selectedRows: [],

      busy: true,

      options: {},
      numItems: 0,

      loaded: false,

      resetPage: false,

      noTagsRule: noTags(this),
    };
  },

  watch: {
    treeActive: {
      immediate: false,
      handler() {
        this.onTreeFilter(this.treeActive);
      },
    },
  },

  computed: {
    deleteMsg() {
      let msg = this.$t("global.action.delete-element");
      if (this.selectedRows) {
        let itemsToDeleteString = this.selectedRows
          .map((row) => row.id)
          .join(", ");
        msg += ` (${itemsToDeleteString})`;
      }
      return msg;
    },

    filterActive() {
      return !!(
        this.keyFilter.key1 ||
        this.keyFilter.key2 ||
        this.keyFilter.key3 ||
        this.keyFilter.key4 ||
        this.keyFilter.key5 ||
        this.topSelectedFileEnv ||
        this.selectedDefinition ||
        this.titleFilter ||
        this.myBps ||
        this.treeSelectedFileEnv ||
        this.treeActive[0]
      );
    },
  },

  methods: {
    formatDate,
    tableHeight,

    onMutate() {
      let height = 0;
      const filters = this.$refs.filters;
      if (filters) {
        if (filters.$el) {
          height = `${filters.$el.offsetHeight}px`;
        } else {
          height = `${filters.offsetHeight}px`;
        }
      }
      document.documentElement.style.setProperty("--filtersHeight", height);
    },

    onClear() {
      this.treeActive = [];
      this.treeKeyFilter = {};
      this.keyFilter = {};
      this.topSelectedFileEnv = undefined;
      this.treeSelectedFileEnv = undefined;
      this.selectedDefinition = undefined;
      this.titleFilter = undefined;
      this.myBps = undefined;
      this.onTopFilter();
      if (this.treeEnabled) {
        this.refreshFolderTree(this.treeSelectedFileEnv);
      }
    },

    async onTopFilter() {
      if (typeof this.noTagsRule(this.titleFilter) === "string") {
        return;
      }
      this.resetPage = !this.resetPage;
      const filters = this.treeEnabled ? this.treeKeyFilter : this.keyFilter;
      this.refreshItems(this.options, filters);
    },

    async goToInstance() {
      if (!this.idFilter) return;
      if (typeof this.noTagsRule(this.idFilter) === "string") {
        return;
      }
      const bp = await get("/businessProcessInstance/" + this.idFilter).catch(
        (error) => this.onError(error)
      );
      if (bp) {
        this.$router.push({
          name: "BP instance",
          params: { bpId: this.idFilter },
        });
      }
    },

    async onOptionsChanged(options) {
      this.$store.commit("setItemsPerPage", options.itemsPerPage);
      if (this.treeEnabled) {
        this.refreshItems(options, this.treeKeyFilter);
      } else {
        this.refreshItems(options, this.keyFilter);
      }
    },

    async onTreeFilter(val) {
      this.resetPage = !this.resetPage;
      this.treeKeyFilter = {};
      if (val && val[0]) {
        val[0].split(";").forEach((keyVal, i) => {
          const key = "key" + (i + 1);
          this.treeKeyFilter[key] = keyVal;
        });
      }
      this.refreshItems(this.options, this.treeKeyFilter);
    },

    async onEnvSelect() {
      this.refreshFolderTree(this.treeSelectedFileEnv);
      this.treeKeyFilter = {};
      this.treeActive = [];
      this.refreshItems(this.options, this.treeKeyFilter);
    },

    async onTreeToggle() {
      this.treeEnabled = !this.treeEnabled;
      this.$store.commit("setTreeEnabled", this.treeEnabled);
      if (this.treeEnabled) {
        this.topSelectedFileEnv = undefined;
        this.treeSelectedFileEnv = undefined;
        this.keyFilter = {};
        this.refreshFolderTree(this.treeSelectedFileEnv);
        this.refreshItems(this.options, this.keyFilter);
      } else {
        this.treeActive = [];
        this.topSelectedFileEnv = undefined;
        this.treeSelectedFileEnv = undefined;
        this.refreshItems(this.options, this.keyFilter);
      }
    },

    async refreshItems(options, keyFilter) {
      this.busy = true;
      this.options = options;
      history.pushState(
        {},
        null,
        this.$route.path + "?" + this.generateFiltersUrlParams(keyFilter)
      );
      const bps = await this.getBps(options, keyFilter);
      if (bps) {
        this.setItems(bps.content);
        this.numItems = bps.totalElements;
      }
      this.busy = false;
    },

    async refreshFolderTree(env) {
      let url;
      if (env) {
        url =
          "businessProcessInstance/organisation-json?envName=" +
          encodeURIComponent(env);
      } else {
        url = "businessProcessInstance/organisation-json";
      }

      this.folderTree = await get(url).catch((error) => {
        this.onError(error);
      });
      if (this.folderTree) {
        this.treeOpen = this.getTreeIds(this.folderTree);
      }
    },

    getTreeIds(tree, depth = 0, maxDepth = 2) {
      let ids = [tree.id];

      if (tree.children) {
        ++depth;
        if (depth >= maxDepth) {
          return ids;
        }
        tree.children.forEach((child) => {
          ids = ids.concat(this.getTreeIds(child, depth));
        });
      }

      return ids;
    },

    create() {
      this.$router.push({ name: "Create BP instance" });
    },

    async getBps(options, keyFilter) {
      if (!options.sortBy) return;
      var sortBy = options.sortBy.length > 0 ? options.sortBy[0] : "id";
      var sortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true;
      var path =
        "/businessProcessInstance/list?page=" +
        options.page +
        "&itemsPerPage=" +
        options.itemsPerPage +
        "&sortBy=" +
        sortBy +
        "&sortDesc=" +
        sortDesc;

      path += this.generateFiltersUrlParams(keyFilter);

      return await get(path).catch((error) => {
        this.onError(error);
      });
    },

    generateFiltersUrlParams(keyFilter) {
      let path = "";

      if (this.topSelectedFileEnv) {
        path += "&env=" + encodeURIComponent(this.topSelectedFileEnv);
      } else if (this.treeSelectedFileEnv) {
        path += "&env=" + encodeURIComponent(this.treeSelectedFileEnv);
      }
      if (keyFilter["key1"]) {
        path += "&key1=" + encodeURIComponent(keyFilter["key1"]);
      }
      if (keyFilter["key2"]) {
        path += "&key2=" + encodeURIComponent(keyFilter["key2"]);
      }
      if (keyFilter["key3"]) {
        path += "&key3=" + encodeURIComponent(keyFilter["key3"]);
      }
      if (keyFilter["key4"]) {
        path += "&key4=" + encodeURIComponent(keyFilter["key4"]);
      }
      if (keyFilter["key5"]) {
        path += "&key5=" + encodeURIComponent(keyFilter["key5"]);
      }
      if (this.selectedDefinition) {
        path += "&definition=" + encodeURIComponent(this.selectedDefinition);
      }
      if (this.titleFilter) {
        path += "&title=" + encodeURIComponent(this.titleFilter);
      }
      if (this.myBps) {
        path += "&myBps=" + encodeURIComponent(this.myBps);
      }
      return path;
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    onRowClick(item) {
      const bpId = item.id;

      this.$router.push({ name: "BP instance", params: { bpId: bpId } });
    },

    async onDelete() {
      this.busy = true;

      const params = this.selectedRows.map((item) => item.id).join("&id=");
      const url = "/businessProcessInstance?id=" + params;
      await remove(url)
        .then(() => this.refreshItems(this.options, this.keyFilter))
        .catch((error) => this.onError(error));
      this.selectedRows = [];

      this.busy = false;
    },

    setItems(bps) {
      this.items = [];
      if (bps) {
        bps.forEach(async (value) => {
          const item = {};
          let envLabel = value.fileEnvLabel;
          let envColor = value.fileEnvColor;
          if (value.local) {
            envLabel = value.parentFileEnvLabel;
            envColor = value.parentFileEnvColor;
          }
          item[this.headers[0].value] = value.id;
          item[this.headers[1].value] = value.title;
          item[this.headers[2].value] = value.definitionName;
          item[this.headers[3].value] = envLabel;
          item.fileEnvColor = envColor;
          item[this.headers[4].value] = value.local;
          item[this.headers[5].value] = value.dateCreated;
          item[this.headers[6].value] = value.status;

          this.items.push(item);
        });
      }
    },
  },

  async created() {
    this.treeEnabled = this.$store.getters.isTreeEnabled;
    if (this.treeEnabled) {
      this.treeSelectedFileEnv = this.$route.query.env;
      // We need to await here because we don't want the tree to be refreshed after we've set treeActive
      await this.refreshFolderTree(this.treeSelectedFileEnv);
      let treeActive = "";
      let treeOpen = [];
      if (this.$route.query.key1) {
        treeActive += this.$route.query.key1;
        treeOpen.push(treeActive);
        this.treeKeyFilter["key1"] = this.$route.query.key1;
      }
      if (this.$route.query.key2) {
        treeActive += ";" + this.$route.query.key2;
        treeOpen.push(treeActive);
        this.treeKeyFilter["key2"] = this.$route.query.key2;
      }
      if (this.$route.query.key3) {
        treeActive += ";" + this.$route.query.key3;
        treeOpen.push(treeActive);
        this.treeKeyFilter["key3"] = this.$route.query.key3;
      }

      if (this.$route.query.key4) {
        treeActive += ";" + this.$route.query.key4;
        treeOpen.push(treeActive);
        this.treeKeyFilter["key4"] = this.$route.query.key4;
      }
      if (this.$route.query.key5) {
        treeActive += ";" + this.$route.query.key5;
        treeOpen.push(treeActive);
        this.treeKeyFilter["key5"] = this.$route.query.key5;
      }
      if (treeActive) {
        this.treeActive = [treeActive];
        this.treeOpen = this.treeOpen.concat(treeOpen);
      }
    } else {
      this.topSelectedFileEnv = this.$route.query.env;
      this.keyFilter["key1"] = this.$route.query.key1;
      this.keyFilter["key2"] = this.$route.query.key2;
      this.keyFilter["key3"] = this.$route.query.key3;
      this.keyFilter["key4"] = this.$route.query.key4;
      this.keyFilter["key5"] = this.$route.query.key5;
    }
    this.myBps = this.$route.query.myBps;
    this.selectedDefinition = this.$route.query.definition;
    this.titleFilter = this.$route.query.title;

    // This is to make sure that we don't create the table before the filters have been set, otherwise onOptionsChanged would be triggered and refresh the runs without the correct filters
    this.loaded = true;

    this.onMutate();

    const options = await get("/businessProcessInstance/filter-options").catch(
      (error) => {
        this.onError(error);
      }
    );

    if (options) {
      this.filterOptions = Object.entries(options);
    }

    this.fileEnvNames = await get("/file-env/names").catch((error) => {
      this.onError(error);
    });

    this.definitionNames = await get("/business-process/names").catch(
      (error) => {
        this.onError(error);
      }
    );
  },
};
</script>
